<template>
    <div class="contact">
     <div class="left">
        <div class="list">
            <img src="../../assets/email.png" alt="">
            <div class="">{{contact.email}}</div>
        </div>
        <div class="list">
            <img src="../../assets/phone.png" alt="">
            <div class="">{{contact.phone}}</div>
        </div>
        <div class="list">
            <img src="../../assets/landline.png" alt="">
            <div class="">{{contact.landline}}</div>
        </div>
        <div class="list">
            <img src="../../assets/address.png" alt="">
            <div class="">{{contact.site}}</div>
        </div>
     </div>
     <div class="right">
         <img :src="contact.code_src" alt="">
     </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    data() {
        return {

        };
    },
    computed:{
        ...mapState(['contact'])
    },
    created() {

    },
    mounted() {

    },
    methods: {

    }
};
</script>

<style scoped lang='less'>
.contact{
    padding: 50px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .left{
       .list{
           margin-top: 10px;
           display: flex;
           align-items: center;
           font-size: 14px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #333;
           img{
            width: 35px;
        height: 35px;
        margin-right: 20px;
       }

       }
       
    }
    .right{
    img{
        width:168px;
        height: 168px;
    }
}

}


</style>
